import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { BannerAlertBox } from '@entur/alert';
import { Link } from '@entur/typography';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "intro"
    }}>{`Intro`}</h1>
    <p>{`Welcome to the Offers API.`}</p>
    <p>{`Offers API provides endpoints for generating and retrieving offers for a combination of origin and destination.`}</p>
    <p>{`The Offers API provide four endpoints:`}</p>
    <ul>
      <li parentName="ul">{`Search for offers with a trip pattern generated from Journey Planner`}</li>
      <li parentName="ul">{`Search for offers with zones from National Stop Register (NSR)`}</li>
      <li parentName="ul">{`Search for offers with stop places from National Stop Register (NSR)`}</li>
      <li parentName="ul">{`Search for offers with your authority id`}</li>
    </ul>
    <p>{`The services enrich the request with information about the journey requested, inventory and seating before identifying all possible offers and calculating price.
Before the response is returned, the services filter unavailable offers and groups offers into recommendations.`}</p>
    <BannerAlertBox title="Offers caching" variant="warning" mdxType="BannerAlertBox">
    Offers API is caching the produced offers for 30 minutes. After this time, the offer can no longer be used in the
    regular <Link href="/pages-sales-guides#reserve-offer" mdxType="Link">sales api</Link>.
    </BannerAlertBox>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`The client should authenticate with the Offers API by providing an OAuth2 bearer token in the request header. The token should be issued by Entur and have the necessary scopes to access the API.
In addition, a header for a specific distribution channel must be provided in the request when `}<a parentName="p" {...{
        "href": "/pages-offers-docs-guides-v2#search-guide"
      }}>{`searching`}</a>{` for offers.
Only distribution channels that have been registered with Entur and have been linked with your client will be accepted.`}</p>
    <p>{`In order to issue a valid token you must have a `}<a parentName="p" {...{
        "href": "/pages-intro-setup-and-access#access-partner-services"
      }}>{`client_id and client_secret`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl--request POST \\
--url 'https://<type_of_token>.<environment>.entur.org/oauth/token' \\
--header 'content-type: application/json' \\
--data '{"grant_type":"client_credentials","client_id": "<clientid>","client_secret": "<clientsecret>","audience": "https://api.<environment>.entur.io"}
`}</code></pre>
    <h2 {...{
      "id": "headers"
    }}>{`Headers`}</h2>
    <p>{`All requests will have a correlation id which is pertained with a request as it propagates through the system.This value will show which request is being processed as it flows
through the system.If no correlation id is provided in the request, one will be generated.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Header key`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Distribution Channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Entur-Distribution-Channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ENT:DistributionChannel:app`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Correlation Id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`X-Correlation-Id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`7c9786df-cadf-4a70-9487-541607ade1b4`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Authorization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authorization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Bearer xxxxxxx.yyyyyyyy.zzzzzzzzz`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "currency"
    }}>{`Currency`}</h2>
    <p>{`The Offers API has a standardised way of representing value of products. Firstly, a currency code is always specified for a given product (e.g. "NOK", "USD", etc.).
Secondly, the amount for the given currency is represented as a numeric string with two decimal points (e.g. "100.00").`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      